import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { createStore , applyMiddleware  } from 'redux';
import thunk from "redux-thunk";
import appReducers from './reducers/appReducers';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './fonts/fonts.css';

// code
const store = createStore(appReducers , applyMiddleware(thunk)  );

ReactDOM.render(
	<Provider store={store} >
		<BrowserRouter forceRefresh={true}>
			<App />
		</BrowserRouter>
	</Provider>, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
