import Http from '../axios/axios';

/*
	post request to api
*/
export const post = async (url, o ) => {

	const obj = {
		response:false,
		status:'',
		data: {}
	};

	try	{
 		const res 		= await Http.post(url , o );
		const { status, data } = res;
		obj.status = status;
		if ( status === 200 ) {
			obj.data = data;
			obj.response = true;
		}
		
	} catch (e) {
		alert(e);
	}	
	return obj
	
};

/*
	post request to api
*/
export const put = async (url, o ) => {

	const obj = {
		response:false,
		status:'',
		data: {}
	};

	try	{
 		const res 		= await Http.put(url , o );
		const { status, data } = res;
		obj.status = status;
		if ( status === 200 ) {
			obj.data = data;
			obj.response = true;
		}
		
	} catch (e) {
		alert(e);
	}	
	return obj
	
};


/*
	post request to api
*/
export const get = async (url, o ) => {

	const obj = {
		response:false,
		status:'',
		data: []
	};

	try	{
 		const res 		= await Http.get(url , o );
		const { status, data } = res;
		obj.status = status;
		if ( status === 200 ) {
			obj.data = data;
			obj.response = true;
		}
		
	} catch (e) {
		alert(e);
	}	
	return obj
	
};

