import { USER_LOGIN,USER_AJAX_ERROR } from '../actions/login.actions';

export const initialState = {
	response: false,
	token:'',
	username: '',
	loginMsg:'',
	siqnInMsg:'',

};
function loginReducer(state = initialState, action) {

 	if (action.type === USER_LOGIN) {
 		const o = {
	        ...state,
	        response: action.payload.response,
          	token: action.payload.token,
    	};
      	if ( action.payload.isSiqnIn === true ) {
      		o.siqnInMsg = action.payload.msg;
      	} else {
      		o.loginMsg 	= action.payload.msg;
      	}
 		return o ;
	}

	if (action.type === USER_AJAX_ERROR) {
		const o = {
	        ...state,
		}
		if ( action.payload.isSiqnIn === true ) {
			o.siqnInMsg= action.payload.msg;
		}else {
			o.loginMsg= action.payload.msg;
		}
 		return o;
	}
	

	return state;

};
export default loginReducer;
