/*
 * Main app config file
 *
*/
export const APP_NAME 		= 'app';

/*
 * APP_DATE_FORMAT
*/
export const APP_DATE_FORMAT = 'DD.MM.YYYY';
/*
 * API URL FOR ALL AJAX CALL
*/
export const API_URL = 'https://vizmlaw.com/';

/*
 * APP IMAGE PATH 
 * CAN LINK TO CDN OR SOME OTHER IMAGE SERVER
*/
export const APP_IMG_PATH = 'https://vizmlaw.com/assets/img/';
// export const APP_IMG_PATH = 'http://localhost:3000/assets/img/';

