import Http from '../axios/axios';
import { post, get, put } from './action-helper';

export const GET_USER			= '(USER) GET_USER';
export const UPDATE_USER			= '(USER) UPDATE_USER';
export const USER_AJAX_ERROR			= '(USER) USER_AJAX_ERROR';


// action creator

export const handleUserResponse = res => {
	return {
		type:res.type,
		payload:{
			...res
		}
	}
}



const handleUserFailureRequestResponse = o => ({
  type: USER_AJAX_ERROR,
  payload: o
});

/*
 * get in user
 * make a GET request user/me 
*/
export const getProfile = (o) => {
 	return async (dispatch) =>  {

		const res =  await get('/user/me' , o);
		const { response, data } = res;
		if ( response === true ) {
			
			dispatch(handleUserResponse({
				type : GET_USER,
				response,
				data,
			}));

		}
 	}
};


/*
 * edit user
 * param o object
 * make a put request user/me 
*/
export const editProfile = (o) => {
 	return async (dispatch) =>  {

		const res =  await put('/user/me' , o);
		const { response, data } = res;

		if ( response === true ) {
			
			dispatch(handleUserResponse({
				type : UPDATE_USER,
				response,
				data,
			}));

			setTimeout(() => {
				dispatch(handleUserResponse({
					type : UPDATE_USER,
					data:{msg:''}
				}));
			},2000);

		}
 	}
};



