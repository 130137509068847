import React , {useContext} from 'react';
import { Routes } from './routes'
import './App.css';
import './ltr.css';

import { getCurrentLang } from './utility/utility';

let cssClass ='';
const currentLang = getCurrentLang();
//  sss
if ( currentLang  != 'he') {
  cssClass += ' app-current-lang-dir-ltr';
}
cssClass += ' app-current-lang-' + currentLang ;


const themes = {

  lang:currentLang,
  light: {
    foreground: "#000000",
    background: "#eeeeee"
  },
  dark: {
    foreground: "#ffffff",
    background: "#222222"
  }
};

export const ThemeContext = React.createContext(themes);

function App() {

  
  return (
    <ThemeContext.Provider value={themes}>
    <div className={'App ' + cssClass }>
      <Routes  />
    </div>
    </ThemeContext.Provider>
  );
}

export default App;
