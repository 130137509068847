import { combineReducers } from 'redux';
import loginReducer from './login.reducer';
import userReducer from './user.reducer';

// Combine with other reducers we may add in the future
const appReducers = combineReducers({
  login:loginReducer,
  user:userReducer
});

export default appReducers;
