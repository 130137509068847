import { GET_USER, USER_AJAX_ERROR , UPDATE_USER } from '../actions/user.actions';

export const initialState = {
	response: false,
	user:{},
	msg: ''
};
function userReducer(state = initialState, action) {

 	if (action.type === GET_USER) {
 		const o = {
	        ...state,
	        user: action.payload.data.user,
          	response: action.payload.response,
    	};
 		return o ;
	}

	if (action.type === UPDATE_USER) {

 		const o = {
	        ...state,
          	response: action.payload.response,
          	msg: action.payload.data.msg,
    	};
 		return o ;
	}

	if (action.type === USER_AJAX_ERROR) {
		const o = {
	        ...state,
		}
		o.response = false;
		o.msg = action.payload.msg;
 		return o;
	}
	
	return state;

};
export default userReducer;
