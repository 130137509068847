/*
	App routes files
*/
import React, { Suspense, lazy, useEffect } from 'react';
import { 	Route,
			Switch, 
			Redirect, 
			BrowserRouter as Router } from 'react-router-dom'

import { isUserLoggedIn } from './utility/utility'

// pages s

// lazy load components
const Home = React.lazy(() => import('./pages/home/home'));
const About = React.lazy(() => import('./pages/about/about'));
const Contact = React.lazy(() => import('./pages/contact/contact'));
const WillsAndInheritances = React.lazy(() => import('./pages/willsAndInheritances/page'));
const Realestate = React.lazy(() => import('./pages/realestate/page'));
const Execution = React.lazy(() => import('./pages/execution/page'));
const Lawsuits = React.lazy(() => import('./pages/lawsuits/page'));
const Condominiums = React.lazy(() => import('./pages/condominiums/page'));
const Takanon = React.lazy(() => import('./pages/takanon/page'));



// const Home = React.lazy(() => import('./pages/auth/home/home'));



// auth routes
const PrivateRoute = ({ isLoggedIn, ...props }) => {
	return isLoggedIn ? <Route { ...props } /> : <Redirect to="/login" />
}

/*
 * Defing client route
 * 
*/

const AppLoader = () => {
	return(
		<div>Loading...</div>
	)
}


const ScrollToTop = ({children}) =>  {

	useEffect(() => {
		setTimeout(() => {
			window.scrollTo(0, 0)
		},0)
		
	},[])
    return (children)
}


export const Routes = () => {
	const loggedIn = isUserLoggedIn();
	return(
		<ScrollToTop>
		<Router>
		  	<Suspense fallback={<AppLoader />}>
				<Switch>
					{/* <Route path="/login" component={Login}/> */}
					{/* <PrivateRoute isLoggedIn={ loggedIn } path="/user/profile" component={userProfile} /> */}
					
					<Route path="/:lang/condominiums" component={Condominiums} />
					<Route path="/:lang/lawsuits" component={Lawsuits} />
					<Route path="/:lang/execution" component={Execution} />
					<Route path="/:lang/realestate" component={Realestate} />
					<Route path="/:lang/willsAndInheritances" component={WillsAndInheritances} />
					<Route path="/:lang/about" component={About} />
					<Route path="/:lang/contact" component={Contact} />
					{/* <Route path="/:lang/takanon" component={Takanon} /> */}
					<Route path="/" component={Home} />
				</Switch>
		 	</Suspense>
		</Router>
		</ScrollToTop>

	)
}
