import React , {useContext} from 'react';
import { getDataFromStorage,cleanAll, createStorage } from '../localStorage/localStorage'


export const logOut = () =>{
  cleanAll();
  window.location.reload()
}
/**
 * hendleError 
 * main app hendle ajax error
*/
export const handleError = ( e )  => {
	console.log(e);
}


/**
 * handleInputChange 
 * main input  ajax error
*/
export const handleInputChange = (event) => {
	const target = event.target;
	const value = target.type === 'checkbox' ? target.checked : target.value;
	const name = target.name;
	return {
		name,
		value
	}
}

export const checkIfFormCanBySubmit = (fields) => {
  let valid = true;
  for (var i = 0; i < fields.length; i++) {
    console.log("fields[i].valid" , fields[i]);

    if ( fields[i].required === true ) {
        if ( fields[i].valid == false ) {
            valid = false;
          }      
    }
    
  };
  return valid 
}



export const checkIfCurrentFieldValid = (fields , o , validationType) => {
  let valid =  false;
  const current = fields.find( (obj) => {
    return obj.name == o.name;
  })
  if ( validationType == 'simple' ) {
      valid  = simpleValidation( o.value );
  } else if ( validationType == 'email' ) {
      valid  = emailValidation( o.value );
  } else if ( validationType == 'password' ) {
      valid  = simpleValidation( o.value , 5  );
  } else if ( validationType == 'number' ) {
      valid  = numberValidation( o.value   );
  } 

  current.valid =   valid;
  current.val = o.value;
  return fields

}

// validation 

export const simpleValidation = ( val , maxLength = 2 ) => {
    if ( ( val.length < maxLength )  || val === ''  ) {
        return false;
    } else {
        return true;
    }
}


export const emailValidation = (val) => {
    return regxValidation({
        val,
        expression: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
    })
}  

export const numberValidation = (val) => {
    return regxValidation({
        val,
        expression: /^[0-9]+$/
    })
}  
// regx validation 
export const  regxValidation = ( o ) => {

    if ( !simpleValidation(o.val) ) {
        return false;
    } else {
        if (o.expression.test(o.val)) {
            return true;
        } else {
            return false;
        }
    }
}

export const isUserLoggedIn = () => {
    return getDataFromStorage('app-token') != null ? true : false ;
}

export const authRedirect = () => {
    window.location.replace('/');
}




export const setCurrentLang = (lang) => {
    
     
    createStorage('app-current-lang' , lang);
    
    var location = window.location.href;
    var link = '';
    if ( lang == 'he' ) {
        link =  location.replace("/en" , '/he');
        link =  link.replace("/ru" , '/he');

    }

    if ( lang == 'en' ) {
        link =  location.replace("/he" , '/en');
        link =  link.replace("/ru" , '/en');
    }

    if ( lang == 'ru' ) {
        link =  location.replace("/he" , '/ru');
        link =  link.replace("/en" , '/ru');
    }
    
    window.location.replace(link);
    
    // window.location.reload();
}


export const getCurrentLang = () => {

    var lang =  getDataFromStorage('app-current-lang');
    if ( lang == null ) {
        lang = 'he'
    }

    return  lang;
}

